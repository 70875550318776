.download-button {
    width:100%;
    border-color: var(--gold);
    border-width: 5px;
    border-radius: 10px;
    background-color: var(--blackPanda);
    color: var(--white);
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
}

.download-button:hover {
    color: var(--black);
    background-color: var(--ice);
    cursor: pointer;
    transition: 0.3s;
}

.button-text {
    font-size: 1.5rem;
    font-family: 'Komikax';
    width:80%;
    text-decoration: none;
}

.button-container {
    width: 100%;
    padding: 0;
    margin: 10px;
    text-decoration: none;
}

@media screen and (max-width: 1000px) {
    .button-text {
        font-size: 1rem;
    }
}