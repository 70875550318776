.plugins-content {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.download-suite-wrapper {
    width: 40%;
    transition: 0.3s;
}

.welcome-text {
    font-size: 2rem;
    text-align: center;
    width:100%;
    transition: 0.3s;
}

@media screen and (max-width: 800px) {
    .download-suite-wrapper {
        width: 70%;
    }

    .welcome-text {
        font-size: 1.75rem;
        text-align: center;
        width:100%;
    }
}