.about-content {
    color: white;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: 0.3s;
}

.contact-section {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    width:100%;
    transition: 0.3s;
}

.contact-text-wrapper {
    display: flex;
    flex-flow: column;
    justify-content: left;
    align-items: left;
    width:30%;
    text-align: left;
}

.contact-image-wrapper {
    width:30%;
    transition: 0.3s;
}

.about-description {
    font-size: 1.5rem;
    width: 75%;
    transition: 0.3s;
}

.about-contact-text {
    font-size: 1.5rem;
    margin-top: 10px;
    margin-bottom: 10px;
    transition: 0.3s;
}

@media screen and (max-width: 800px) {
    .contact-section {
        flex-flow: column-reverse;
    }
    
    .about-description {
        font-size: 1.5rem;
        width: 80%;
        text-align: center;
    }
    
    .about-contact-text {
        font-size: 1.5rem;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .contact-text-wrapper {
        flex-flow: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 50px;
        width: 100%;
    }

    .contact-image-wrapper {
        width:90%;
    }
}