:root {
  --black: #000000;
  --blackPanda: #333333;
  --white: #ffffff;
  --grey: #3a3a3a;
  --gold: #fede68;
  --ice: #a4fefc;
}

* {
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Komikax';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  width:100%;
}

h1 {
  font-family: 'Komikax';
  color: var(--white);
  font-size: 1.5rem;
}

h2 {
  font-family: 'Komikax';
  color: var(--ice);
  font-size: 1.5rem;
}


@font-face {
  font-family: Komikax;
  src: url("assets/fonts/KOMIKAX.woff2") format("woff2");
}
