.App {
  background-color: var(--blackPanda);
  overflow-x: hidden;
}

.app-content {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}


