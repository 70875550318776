.plugin-card-container {
    width: 75%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 30px;
    color: var(--white);
    transition: 0.3s;
}

.image-container {
    width: 50%;
    margin: 10px;
    transition: 0.3s;
}

.plugin-title {
    font-family: 'Komikax';
    font-size: 2rem;
    color: var(--gold);
    transition: 0.3s;
}

.contribute-link {
    font-family: 'Komikax';
    font-size: 1.5rem;
    color: var(--ice);
    margin-bottom: 10px;
    transition: 0.3s;
}

.contribute-link:hover {
    color: var(--white);
    cursor: pointer;
    transition: 0.3s;
}

.plugin-description {
    font-size: 1.5rem;
    color: var(--white);
    width: 50%;
    transition: 0.3s;
}

.download-buttons-container {
    display: flex;
    flex-flow: row;
    width: 40%;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
}

.demo {
    width:600px;
    height:350px;
    transition: 0.3s;
}

@media screen and (max-width: 800px) {
    .image-container {
        width: 90%;
        margin: 10px;
    }

    .plugin-description {
        font-size: 1.25rem;
        color: var(--white);
        width: 90%;
    }

    .download-buttons-container {
        flex-flow: column;
    }

    .demo {
        width:280px;
        height:160px;
    }

    .download-buttons-container {
        display: flex;
        flex-flow: row;
        width: 70%;
        justify-content: center;
        align-items: center;
    }
}