.header-container {
    width:100%;
    overflow-x: hidden;
    background-color: var(--black);
    height: 5rem;
    padding: 20px;
    align-items: center;
    justify-content: left;
    display: flex;
    margin-bottom: 50px;
    top:0;
}

.header-title {
    font-family: 'Komikax';
    color: var(--white);
    font-size: 2rem;
    width: 50%;
    padding-left: 20px;
    text-decoration: none;
    transition: 0.3s;
}

.nav-title {
    font-family: 'Komikax';
    color: var(--white);
    font-size: 1.5rem;
    margin: 10px;
    transition: 0.3s;
    text-decoration: none;
}

.nav-title:hover {
    cursor: pointer;
    color: var(--gold);
    transition: 0.3s;
}

.nav-title:active {
    cursor: pointer;
    color: var(--ice);
}

.nav-bar {
    width: 50%;
    align-items: center;
    justify-content: right;
    display: flex;
    padding: 20px;
}

@media screen and (max-width: 1000px) {
    .header-title {
        font-size: 1.25rem;
    }

    .nav-title {
        font-size: 1.25rem;
    }
}
