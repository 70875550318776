.footer-container {
    position: relative;
    display: flex;
    flex-flow: column;
    background-color: var(--black);
    width: 100%;
    bottom: 0;
    height: 15rem;
    justify-content: center;
    align-items: center;
    color: var(--white);
}

.logo-container {
    width: 5rem;
}